.progress_bar_container {
  height: 25px;
  width: 100%;
  border: 1px solid var(--app-border-grey);
  border-radius: 10px;
}

.progress_bar {
  height: 100%;
  border-radius: 10px;
}

.subtext {
  width: 100%;
  text-align: center;
}
