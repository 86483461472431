.onboard_modal_container {
  position: absolute;
  top: 0px;
  left: unset;
  right: 0;
  transform: none !important;
  max-height: none;
  height: 100%;
  margin: 0;
  max-width: none !important;
}

.status_popup {
  position: absolute;
  bottom: 60px;
  left: 10%;
  right: 10%;
  z-index: 9999;
}

.footer_options_container {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.footer_left {
  display: flex;
  align-items: center;
  margin-left: 3.3%;
}
