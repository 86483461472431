.container {
  margin-right: auto;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.button {
  display: flex;
  flex-direction: row;
  text-align: center;
  background-color: var(--app-white);
  border-radius: 3px;
  color: var(--text-primary);
  height: 40px;
  padding: 0px 20px;
  border: 1px solid var(--app-border-grey);
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 500;
}

.button:hover {
  color: var(--app-blue);
  background-color: var(--app-lightblue);
}

.button:active {
  opacity: 0.7;
}

.button_selected {
  border-color: var(--app-blue);
  background-color: var(--app-lightblue);
  color: var(--app-blue);
  font-weight: bold;
}
