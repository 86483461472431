.rdt {
  position: relative;
}

.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9;
}

.rdtOpen .rdtPicker {
  display: block;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}

.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}

.rdtPicker th.rdtSwitch {
  width: 100px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
  cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtCounter .rdtBtn:hover {
  background: #eee;
}

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.change-img > .md-form {
  margin-top: 0;
}

.modal-backdrop.fade {
  opacity: 0.6;
}

.modal {
  opacity: 1 !important;
}

.modal-header {
  border-bottom: 2px solid #e9ecef;
}

.modal-content {
  border-bottom: 2px solid #e9ecef;
}

.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -10%);
  transform: translate(0, 10%);
}

.route-modal-title {
  text-align: left;
  padding-bottom: 0em;
  font-weight: bold;
  padding-left: 5px;
}

.route-modal-header {
  display: inline-block;
}

.header-button-group {
  position: relative;
  text-align: left;
  margin-top: 1em;
}

.header-button-group > h3 {
  display: inline-block;
  font-size: 1.55rem;
}

#department-dropdown {
  top: 1em;
  margin-bottom: 1em;
}

#zone-dropdown {
  top: 1em;
}

#add-order-route {
  right: 1em;
  bottom: 1em;
  border: none;
}

.gateway-alerts {
  margin-top: 0px;
  margin-bottom: 8px;
  padding-bottom: 5px;
  padding-top: 0em;
}

.gateway-alerts > h4 {
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.gateway-alerts > p {
  margin-bottom: 2px;
  padding-top: 3px;
}

.alert-danger {
  margin-top: 0rem !important;
}

.order-backlog-body {
  padding-top: 0;
}

.expanded-department-name {
  font-size: 0.9em;
  padding-left: 6%;
  width: 37%;
}

.expanded-department-time-duration {
  font-size: 0.9em;
}

.department-time-duration {
  font-size: 0.9em;
  width: 15%;
}

.time-duration {
  font-size: 0.9em;
  flex: 0.33 1;
  text-align: left;
}

.expanded-time-duration {
  font-size: 0.9em;
  flex: 0.45;
}

.tracking-department-name {
  padding-left: 2%;
  font-size: 0.9em;
  width: 37%;
}

.tracking-department-name {
  padding-left: 2%;
  font-size: 0.9em;
  width: 12%;
}

.expanded-tracking-department-name {
  font-size: 0.9em;
  flex: 0.3 1;
  padding-left: 8.4%;
}

.tracking-departments-links-detail {
  display: flex;
  flex-direction: column;
  padding-left: 1%;
  padding-right: 1%;
  background-color: white;
}

.tracking-departments-links {
  padding-top: 0.4em;
}

.stages-detail {
  width: 44%;
}

.time-detail {
  text-align: left;
  flex: 0.25;
}
.compare-detail {
  margin-right: 5%;
}

.tracking-department-orders {
  font-size: 0.9em;
}

.tracking-department-link-row > .tracking-department-orders {
  width: 15vw;
}

.tracking-department-link-row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  background-color: var(--app-white);
  /* margin-bottom: .1rem; */
  color: #4c4c4c;
  border-bottom: 1pt solid #f6f6f6;
  padding-top: 1%;
  padding-bottom: 1%;
}

.tracking-department-link-row {
  flex-direction: row;
  flex: 1;
  display: flex;
  background-color: var(--app-white);
  /* margin-bottom: .1rem; */
  color: #4c4c4c;
  border-bottom: 1pt solid #f6f6f6;
  padding-top: 1%;
  padding-bottom: 1%;
}
.tracking-departments-links {
  display: flex;
  flex-direction: column;
  padding-top: 0;
}

.departments-links-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #003f73;
  color: var(--app-white);
  padding-left: 2%;
  padding-right: 2%;
  font-size: 0.9em;
}

.tracking-links-header {
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: var(--app-blue);
  color: var(--app-white);
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  font-size: 0.9em;
}

.route-table-header {
  text-align: left;
}

.edit-form-buttons {
  display: flex;
  justify-content: space-evenly;
  padding: 0.3em;
}

.cancel-button {
  background-color: #eaeaea;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #eaeaea;
  color: var(--text-primary);
  margin-right: 4%;
  width: 10em;
}

@media only screen and (min-width: 768px) {
  /* .object-page-items {
        padding-left: 16%;
        padding-right: 5%;
    } */
  #object-page-title {
    font-size: x-large;
    padding-bottom: 5%;
    text-align: center;
  }
  #route-title {
    text-align: center;
    padding-top: 5%;
  }
  #add-order-route {
    position: absolute;
  }
  .stages-detail {
    width: 34%;
  }
  .compare-detail {
    margin-right: 2%;
  }
  .order-backlog-body {
    display: flex;
    padding-top: 0;
    flex-direction: column;
  }
  .tracking-departments-links {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 1rem;
    margin-right: 1rem;
    align-self: center;
    flex-grow: 1;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .tracking-department-tables {
    display: flex;
    padding-bottom: 5%;
  }
  .tracking-department-link-row > .tracking-department-orders {
    text-align: center;
  }
  .header-button-group > h3 {
    margin-left: 0.6em;
  }
  .gateway-alerts > h4 {
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}
