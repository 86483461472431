.accordion_container {
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  margin-bottom: 0.8em;
}

.accordion {
  display: flex;
  align-items: center;
  padding-right: 20px;
  min-width: 10%;
}

.accordion_title {
  color: #14396a;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accordion_title_icon {
  margin-right: 10px;
  border-radius: 15px;
  background-color: #14396a;
  width: 17px !important;
  height: 17px !important;
  cursor: pointer;
}

.metrics_group {
  display: flex;
  flex-direction: row;
}

.metric_container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-right: 20px 0px;
}

.metric_label {
  font-size: 12px;
  font-weight: 600;
  color: var(--text-primary);
  padding-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.metric_value {
  font-size: 12px;
  color: var(--text-primary);
}

.accordtion_btn {
  font-weight: 500;
  font-size: 12px;
  color: #14396a;
  margin-left: auto;
  cursor: pointer;
}

.accordtion_btn:hover {
  text-decoration: underline;
}

.skeleton_cols {
  margin-left: 15px;
  margin-right: 15px;
}

.skeleton_row {
  height: 55px;
  border-style: solid;
  border-color: var(--app-border-grey);
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right: none;
  border-left: none;
  align-items: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
