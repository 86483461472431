.dropdown_option_container {
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.dropdown_option_container:hover {
  background-color: var(--app-offwhite);
}

.dropdown_option_text {
  color: var(--text-primary);
  font-size: 16px;
  font-weight: 600;
}

.dropdown_option_secondary_text {
  color: var(--text-primary);
  font-size: 14px;
}

.dropdown_blue_text {
  color: var(--app-blue);
}

.dropdown_selected_text {
  color: var(--text-secondary);
}

.selected_items_list_container {
  margin-top: 12px;
}
