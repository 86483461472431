.flex_row {
  display: flex;
  flex-direction: row;
}

.run_report_button_container {
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.run_report_button,
.run_report_button:hover {
  text-align: center;
  flex: 1;
  background-color: var(--app-blue);
  border-radius: 5px;
  color: white;
  padding: 15px;
  font-weight: bold;
}

.run_report_button:hover {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.6);
}

.side_filter_container {
  padding: 20px;
}
