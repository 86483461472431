.modal_container {
  width: 960px;
  max-width: 960px !important;
}

.title_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  color: var(--inventory-primary);
  border-color: var(--inventory-secondary);
  stroke-width: 5;
}

.header_title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: var(--font-size-base);
  color: var(--text-primary);
}

.modal_body_container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  min-width: 720px;
}

.tab_bar {
  border-bottom: 1px solid var(--app-border-grey);
}

.tab_container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.printer_component {
  padding: 10px 35px;
}

.save_checkbox_container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.save_label {
  cursor: default;
  font-style: normal;
  vertical-align: middle;
}
