.switch_container {
  margin-bottom: 1em;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.switch_text {
  min-width: max-content;
  margin-top: 0.3em;
  margin-right: 0.6em;
  font-size: small;
  font-style: italic;
}
