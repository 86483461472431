.modal_content {
  height: auto;
}

.modal_header {
  margin: 0;
  padding-left: 30px;
  padding-top: 25px;
}

.modal_header > div {
  font-weight: 700;
  font-size: 1.2rem;
}

.modal_body {
  padding: 20px 30px 30px 30px;
}
