.grid {
  display: grid;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.modal {
  position: absolute;
  top: 0px;
  right: 0px;
  transform: none !important;
  min-width: 700px;
  max-width: 1200px;
  width: 64%;
  height: 100%;
  margin: 0;
}

.header_container {
  color: var(--text-primary);
  flex: 1;
  letter-spacing: 0.5px;
}

.header_close_button {
  width: 35x;
  height: 35px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background: none;
  border: none;
  color: var(--app-blue);
}

.header_close_button:hover {
  opacity: 0.7;
}

.header_close_button_icon {
  font-size: 2rem !important;
}

.header_title_container {
  align-items: center;
  gap: 15px;
}

.header_title_icon_container {
  border: 4.5px solid var(--xemelgo-light-blue);
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_title_container > p {
  font-weight: bold;
  font-size: 1.2rem;
}

.footer_container {
  gap: 20px;
  color: var(--text-primary);
  letter-spacing: 0.5px;
}

.footer_container > button {
  background: none;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 3px;
  padding: 8px 25px;
  letter-spacing: inherit;
}

.footer_container > button:disabled {
  opacity: 0.2;
}

.footer_container > button:hover {
  opacity: 0.7;
}

.footer_container > button:first-child {
  background-color: #eeeeee;
}

.footer_container > button:last-child {
  color: white;
  background-color: var(--app-blue);
}

.body_container {
  position: relative;
  flex: 1;
}

.section {
  padding: 30px;
  gap: 20px;
  flex: 1;
}

.report_type_section {
  background-color: #fbfafa;
}

.section_title {
  font-weight: bold;
  font-size: 18px;
}

.input_group_list {
  margin-top: 10px;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20%;
  row-gap: 20px;
}

.input_container {
  max-width: 350px;
}

.attribute_label {
  font-weight: bold;
}

.label_asterisk {
  color: red;
  margin-left: 5px;
}

.loading {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.1);
}
