.basic_add_form_style {
  display: inherit;
  justify-content: space-around;
  width: 100%;
}

.form_content_with_error {
  min-height: 5.5em;
  width: 100%;
}
