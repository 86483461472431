.title-inventory {
  margin: 20px;
  margin-top: 0px;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.01rem;
}

.list-card {
  margin: 20px;
  margin-right: 0;
  min-height: 600px;
}

.list-item-checked {
  background-color: rgb(199, 222, 253);
}

.menu-box {
  left: -200px !important;
  top: 100px !important;
  padding: 8px !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.comments-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.comments {
  cursor: pointer;
  display: flex;
  min-width: 100px;
  height: 4em;
  width: 13em;
  border: 1px solid var(--app-border-grey);
  border-radius: 5px;
  padding: 0.4em;
}

.input-field {
  resize: none;
  display: flex;
  background: white;
  width: 400px;
  height: 200px;
  padding: 5px;
  font-size: 14px;
  border-radius: 5px;
  border: 0.1em solid rgb(49, 131, 238);
}

.checkbox-outer {
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 1px;
  border-radius: 2px;
  border: thin solid #dcdcdc;
}

.checkbox-checked {
  height: 100%;
  width: 100%;
  background-color: #1e71d2;
  border-radius: 2px;
}

.checkbox-unchecked {
  height: 100%;
  width: 100%;
  border-radius: 2px;
  background-color: white;
}

.action-bar {
  width: 100%;
  bottom: 0px;
  left: 0px;
  /* position: absolute; * /*This hides the last request when we select it*/
  font-size: 14px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.close {
  color: black;
  border-radius: 5px;
  cursor: pointer;
}

.close:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.number {
  background-color: rgb(49, 131, 238);
  border-radius: 3px;
  color: white;
  font-weight: lighter;
}

.bold {
  font-weight: bolder;
}

.calendar {
  border: none !important;
}

.calendar-button-group {
  margin: 5px 10px 5px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.submit {
  padding: 5px;
  cursor: pointer;
  color: rgb(49, 131, 238);
  border-radius: 5px;
}

.submit-disabled {
  padding: 5px;
  cursor: pointer;
  color: rgb(153, 153, 153);
  border-radius: 5px;
}

.submit:hover {
  cursor: pointer;
  color: white;
  background-color: rgb(49, 131, 238);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.cancel {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  color: red;
}

.cancel:hover {
  cursor: pointer;
  background-color: red;
  color: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.change-status {
  border-radius: 5px;
  cursor: pointer;
}

.change-status:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.action-small {
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.action-xlarge {
  padding: 4px;
  font-weight: bold;
  margin: 10px, 50px, 10px 4px;
}

.action-large {
  padding: 5px;
  margin: 10px 25px 10px 25px;
}

.delete-request {
  border-radius: 3px;
  cursor: pointer;
  color: red;
}

.delete-request:hover {
  cursor: pointer;
  color: white;
  background-color: red;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.edit-request {
  border-radius: 5px;
  cursor: pointer;
}

.edit-request:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.list-item {
  padding: 10px 20px 10px 20px;
  height: 75px;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 10px 30px 10px 30px;
}

.list-item-title {
  margin-left: 30px;
  margin-right: 30px;
  padding: 10px 20px 10px 20px;
  height: 75px;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.border-grey {
  border: 0 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  border-left: 0.5em solid rgb(153, 153, 153) !important;
}

.border-green {
  border: 0 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  border-left: 0.5em solid rgb(49, 198, 58) !important;
}

.border-red {
  border: 0 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  border-left: 0.5em solid rgb(246, 34, 39) !important;
}

.border-yellow {
  border: 0 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  border-left: 0.5em solid rgb(255, 179, 33) !important;
}

.border-blue {
  border: 0 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  border-left: 0.5em solid rgb(49, 131, 238) !important;
}

.border-purple {
  border: 0 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  border-left: 0.5em solid rgb(138, 79, 251) !important;
}

.background-grey {
  background-color: rgb(234, 234, 234);
  color: rgb(153, 153, 153);
}

.background-green {
  background-color: rgb(210, 247, 197);
  color: rgb(22, 56, 9);
}

.background-red {
  background-color: rgb(246, 213, 217);
  color: rgb(189, 24, 28);
}

.background-yellow {
  background-color: rgb(255, 234, 183);
  color: rgb(198, 78, 0);
}

.background-blue {
  background-color: rgb(168, 218, 255);
  color: rgb(22, 110, 213);
}

.background-purple {
  background-color: rgb(236, 226, 252);
  color: rgb(99, 46, 200);
}

.status {
  font-weight: bold;
  letter-spacing: 0.05em;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
  padding: 3px;
}

.item-label {
  color: rgb(153, 153, 153);
  font-weight: bold;
  font-size: 15px !important;
  padding-left: 9px;
}

.item-large-name {
  font-size: 14px;
  margin: 10px;
  flex: 0.2;
  overflow: hidden;
}

.item-large {
  font-size: 14px;
  margin: 10px;
  flex: 0.2;
}

.item-medium {
  font-size: 14px;
  margin: 10px;
  flex: 0.1;
}

.item-small {
  font-size: 14px;
  margin: 10px;
  flex: 0.05;
}

/* Added this for edit request */
.edit-box-large {
  margin: 2px;
  flex: 0.2;
  padding-top: 9.6px;
  padding-bottom: 30px;
}

.edit-box-medium {
  margin: 19px;
  flex: 0.1;
  padding-top: 9.6px;
  padding-bottom: 30px;
}

.edit-box-small {
  margin: 15px;
  flex: 0.05;
  padding-top: 9.6px;
  padding-bottom: 30px;
}
