.form_container {
  width: 42vw;
  margin: auto;
  justify-content: center;
}

.form_content {
  width: 100%;
  min-width: 350px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.input_container {
  min-height: 100px;
}

.required_asterisk {
  color: var(--status-red);
  margin-left: 4px;
}

.input_error_text {
  font-size: 14px;
  margin-top: 5px;
  margin-left: 10px;
  max-width: 240px;
  color: var(--status-red);
  letter-spacing: 0.5px;
  overflow-wrap: break-word;
}

.radio_group_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 41.5vw;
  min-width: 310px;
}

.radio_group_container > label {
  font-weight: bold;
  margin-bottom: 8px;
}

.radio_item {
  display: flex;
  align-items: center;
  margin: 5px 0;
  gap: 10px;
}

.radio_item input[type="radio"] {
  accent-color: var(--app-blue);
  margin-right: 8px;
}

.radio_label {
  margin-top: 3px;
}

input {
  transition: background-color 3000s ease-in-out 0s;
}

@media only screen and (max-width: 1200px) {
  .form_container {
    width: 100%;
    max-width: 1200px;
    min-width: 300px;
    margin: auto;
    justify-content: center;
  }
  .form_content {
    width: 100%;
    max-width: 1200px;
    min-width: 300px;
    justify-content: center;
    margin-bottom: 10px;
  }
}
