.body_container {
  padding: 26px 35px 30px 35px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  background: #fbfafa;
}

.input_group {
  flex-wrap: wrap;
  row-gap: 30px;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 35px;
}

.input_container {
  font-weight: bold;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex {
  display: flex;
}

.detector_group_input {
  display: flex;
  flex-direction: column;
  width: 200px;
  font-size: 90%;
}

.detector_container {
  padding: 26px 35px 50px 35px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.detector_group_button {
  text-align: end;
  color: var(--app-blue);
  cursor: pointer;
  font-weight: normal;
  font-size: 90%;
}

.detector_group_container {
  padding-bottom: 35px;
}

.add_detector_text {
  font-weight: bold;
  padding-top: 2px;
}

.add_icon_container {
  height: 1em;
  width: 1em;
  color: var(--app-white);
  background-color: var(--app-blue);
  margin: 0px 10px 0px 10px;
  border-radius: 100%;
  display: flex;
}

.add_button_container {
  margin-top: 25px;
  height: 45px;
  width: 155px;
  background-color: var(--app-offwhite);
  width: 155px;
  align-items: center;
  display: flex;
  cursor: pointer;
  border-radius: 3px;
}

.remove_icon_container {
  margin: 10px 0px 0px 10px;
  height: 1.2em;
  width: 1.2em;
  color: var(--status-red);
  border: 1.3px solid var(--status-red);
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
}

.normal_text {
  font-weight: normal;
}

.detector_input {
  margin-left: 80px;
}

.detector_input_container {
  display: flex;
  margin-top: 10px;
}

.detector_input_group_container {
  padding: 10px;
  border: 1px solid var(--app-border-grey);
  display: flex;
  flex-direction: column;
  margin: 30px 0px 0px 30px;
}
