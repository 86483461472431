.password_requirement_strip {
  display: flex;
  align-items: center;
  margin-top: 0.1em;
  margin-bottom: 0.1em;
}

.password_requirement_icon {
  margin-right: 0.6em;
}

.password_requirement_label {
  padding-top: 0.2em;
  vertical-align: middle;
}

.password_requirement_label_color {
  color: var(--app-white);
}
