.dropdown_no_result_message {
  text-align: center;
  color: var(--app-lightgrey);
  padding: 1rem;
  font-weight: 400;
  font-size: 15px;
  margin: auto;
}

.loading_container {
  padding: 10px 20px;
}

.dropdown_item {
  padding: 0.75rem 1rem;
  cursor: pointer;
  font-weight: 400;
}

.dropdown_item:hover {
  background-color: var(--app-offwhite);
}

.dropdown_label_container {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none; /* For Firefox */
  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
}
