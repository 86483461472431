.flex_row {
  display: flex;
  flex-direction: row;
}

.empty_list_container {
  justify-content: center;
  align-items: center;
  padding: 200px 0px;
}

.empty_list_text {
  font-weight: bold;
  color: var(--text-secondary);
}

.loading_icon {
  flex: 1;
  height: 24px;
}

.table_header {
  flex: 1;
}

.table_column {
  flex: 1;
  padding: 10px;
  align-items: flex-start;
  gap: 5px;
}

.table_column:first-of-type {
  flex: 3;
}

.table_column:last-of-type {
  flex: 0.7;
}

.table_header_text {
  font-weight: bold;
}

.table_item_text {
  color: var(--app-blue);
  cursor: pointer;
  letter-spacing: 0.5px;
}

.table_item_text:hover {
  text-decoration: underline;
}

.row_identifier_column {
  justify-content: space-between;
}

.auto_approved_text {
  color: var(--status-green);
  margin-right: 30px;
}
