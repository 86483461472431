.summary_tile_top_content {
  margin-left: 0.6em;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.flex_direction_row {
  display: flex;
  flex-direction: row;
}

.tile_container {
  display: flex;
  justify-content: space-evenly;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding: 0.5em;
}

.item_description {
  clear: both;
  height: 1.5em;
  padding-top: 0.4em;
  margin-top: 0.1em;
  font-weight: bold;
}

.pagination_container {
  display: flex;
  margin-left: auto;
  padding-top: 0.6em;
  margin-right: 0.5em;
  clear: both;
}

.top_content_text {
  font-size: 0.8em;
  font-weight: 600;
  margin-bottom: 0.6em;
}

.top_content_text_lg {
  font-size: 1.2em;
}

.bottom_content_text {
  font-size: 0.85em;
  color: var(--text-primary);
}

.summary_tile_color_bar {
  height: 0.5em;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  margin-bottom: 0.4em;
}

.view_tab_btn,
.view_tab_btn_active {
  color: var(--text-primary);
  font-size: 0.8em;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 20px 5px 20px;
  margin-right: 20px;
  font-weight: 500;
  background-color: white;
  height: 32px;
  align-items: center;
  display: flex;
}

.view_tab_btn_active {
  background-color: var(--app-blue);
  color: white;
}

.overview_right_container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.overview_right_container_item {
  margin: 10px;
  margin-bottom: 1em;
  margin-top: 0px;
  padding-top: 1em;
  border-radius: 5px;
  border: 1px solid var(--app-border-grey);
  min-width: 220px;
  min-height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1.6em;
  height: 100%;
}

.kpi_tile_title {
  color: #92929d;
  font-weight: 500;
}

.kpi_text {
  margin-bottom: 1.6em;
}

.overview_right_value {
  font-size: 18px;
  color: var(--text-primary);
  font-weight: 600;
  margin-bottom: 1.6em;
}

.list_view_status {
  margin-top: 4px;
  margin-bottom: 3px;
  padding: 3px 12px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  max-width: 87px;
  color: var(--app-white);
}

.more_button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: var(--app-blue);
  color: var(--app-blue);
  width: 1.4em;
  height: 1.4em;
  border-radius: 23px;
  margin-left: 1em;
  padding: 10px 10px;
  cursor: pointer;
}

.options_container {
  padding: 0;
  min-width: 150px;
}

.option_item {
  padding: 0;
  height: 35px;
}

.option_text {
  padding-left: 10px;
  font-size: 0.9em;
  color: var(--app-blue);
}
