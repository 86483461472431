.container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions_page_container {
  padding: 40px;
  flex-wrap: wrap;
  justify-self: center;
  display: flex;
  gap: 60px;
  flex: 1;
}

.actions_page_container_row {
  flex-direction: row;
}

.actions_page_container_column {
  flex-direction: column;
}

.action_button_container {
  flex-basis: calc(50% - 30px);
  display: flex;
  justify-content: center;
}

.action_button {
  padding: 20px 40px;
  min-height: 120px;
  flex: 0.8;
  background-color: var(--app-blue);
  color: var(--app-white);
  font-size: var(--font-size-xlarge);
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  /* border: 1px solid red; */
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
}

.action_button:hover {
  opacity: 0.9;
}

@media only screen and (max-width: 800px) {
  .actions_page_container_row {
    flex: 1;
    flex-direction: column;
  }
}
