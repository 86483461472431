.grid {
  display: grid;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.input_group_list {
  margin-top: 10px;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20%;
  row-gap: 30px;
}
.run_type_container {
  gap: 20px;
}
.input_container {
  max-width: 350px;
}

.attribute_label {
  font-weight: bold;
}

.label_asterisk {
  color: red;
  margin-left: 5px;
}

.checkbox_list,
.date_picker_list {
  gap: 40px;
}

.checkbox,
.date_picker {
  align-items: center;
  gap: 10px;
}

.date_picker {
  min-width: 300px;
}

.checkbox > p {
  font-weight: 500;
  margin-top: 2px;
}

.date_picker > p {
  font-weight: 500;
}
