.input_field_style {
  height: 40px;
  margin-top: 0.5em;
  width: 100%;
  margin-right: 10px;
  border-radius: 3px;
  border: 1px solid var(--app-border-grey);
  padding-left: 1em;
}

.container {
  flex: 1;
  min-width: 100px;
  margin-right: 1em;
}
