.flex_row {
  display: flex;
  flex-direction: row;
}

.selectable_item_node_container {
  border-bottom: 1px solid var(--app-border-grey);
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.checkbox_container {
  display: flex;
  align-items: top;
  margin: 10px;
  margin-right: 10px;
}

.checkbox_container_align_center {
  align-items: center;
}

li {
  list-style-type: none;
}

ul {
  margin: 0;
  padding: 0;
  justify-content: flex-start !important;
}

.expand_icon_container {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.expand_icon {
  font-weight: 500;
  font-size: 16px;
  color: var(--app-blue);
  cursor: pointer;
}

.row_detail {
  margin-top: 10px;
  margin-left: 15px;
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #fafafa;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.expand_padding {
  padding-left: 40px;
}

.hidden {
  visibility: hidden;
}
