/* These styles are to override the modal form styles */
.detector-body {
  padding: 0;
}

.detector-title {
  width: 100%;
}

.detector-header {
  padding: 0;
}

.modal-content {
  max-height: none !important;
}
