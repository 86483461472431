.grid {
  display: grid;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.table_container {
  padding-top: 5px;
  padding-bottom: 5px;
  min-width: 1050px;
}

.table_empty {
  display: flex;
  justify-content: center;
  align-content: center;
  color: grey;
  font-weight: 500;
}

.table_header {
  flex: 1;
  align-items: center;
  gap: 20px;
}

.selection_container {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: var(--neutral-secondary);
  font-size: 14px;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  padding-left: 1em;
  border-radius: 3px;
}

.selection_buttons_container {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
}

.selection_count_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selection_count_text {
  font-weight: bold;
  margin-right: 0.2em;
}

.selection_button {
  padding: 0 20px 0 20px;
  color: var(--app-blue);
  cursor: pointer;
}

.edit_schedule_modal {
  transform: none !important;
  height: 300px;
  width: 500px;
}

.modal_body {
  display: flex;
  flex-direction: column;
}

.modal_text {
  padding-bottom: 10px;
  width: 100%;
}

.modal_list_text {
  padding-bottom: 10px;
  width: 100%;
}

.edit_items_overflow {
  border-bottom: 1px dotted #ccc;
  color: var(--app-blue);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip_container {
  margin-top: 10px;
}

.input_group_list {
  display: flex;
  flex: row;
}

.bolded_text {
  font-weight: 600;
}

.date_picker,
.search_dropdown {
  width: 220px;
}

.modal_item {
  padding-right: 20px;
}

.modal_footer {
  gap: 20px;
  color: var(--text-primary);
  letter-spacing: 0.5px;
}

.modal_footer > button {
  background: none;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 3px;
  padding: 8px 25px;
  letter-spacing: inherit;
}

.modal_footer > button:disabled {
  opacity: 0.2;
}

.modal_footer > button:hover {
  opacity: 0.7;
}

.modal_footer > button:first-child {
  background-color: #eeeeee;
}

.modal_footer > button:last-child {
  color: white;
  background-color: var(--order-primary);
}
