.flex_row {
  display: flex;
  flex-direction: row;
}

.table_column {
  flex: 1;
  padding: 10px;
  align-items: center;
  gap: 5px;
}

.table_header_text {
  font-weight: bold;
  font-size: 14px;
}

.table_item {
  border-bottom: 1px solid var(--app-border-grey);
  margin-bottom: 1px;
  flex: 1;
}

.table_item_text {
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: 400;
  word-break: normal;
  overflow-wrap: anywhere;
}

.table_item_text_clickable {
  color: var(--app-blue);
  cursor: pointer;
}

.table_item_text_clickable:hover {
  text-decoration: underline;
}

.color_indicator {
  width: 10px;
}

.header_with_color_indicator {
  padding-left: 10px;
}

.priority_number_column {
  padding-left: 20px;
  min-width: 80px !important;
  width: 80px !important;
  max-width: 80px;
  flex: 0 !important;
  align-items: center;
}

.priority_number_text {
  margin: auto;
  text-align: center;
  font-weight: 500;
  padding-right: 20px;
}
