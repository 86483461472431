.metrics_container {
  color: var(--xemelgo-blue);
}

.title {
  color: var(--text-primary);
  font-weight: 600;
}

.grid_container {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  row-gap: 10px;
  margin-top: 6px;
}

.metric_box {
  width: 210px;
  height: 110px;
  border-radius: 3px;
  background-color: var(--xemelgo-light-blue);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading_box {
  width: 210px;
  height: 106px;
  border-radius: 3px;
}

.metric_value {
  font-weight: 700;
  font-size: 26px;
}

.metric_label {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--text-primary);
  margin-top: 3px;
}

.metric_label_container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
}

.info_icon {
  width: 16px;
  height: 16px;
  margin-left: 6px;
}

.info_icon:hover {
  cursor: pointer;
}

.info_icon * {
  stroke-width: 4px;
}

.metric_description_container {
  position: absolute;
  right: 0;
  width: 200px;
  font-size: 12px;
  font-weight: 500;
  z-index: 999;
  color: var(--text-primary);
  background-color: white;
  padding: 6px 4px;
  border-radius: 3px;
  border: 1px solid var(--app-border-grey);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.1s linear;
  height: min-content;
}

.info_container:hover .metric_description_container {
  visibility: visible;
  opacity: 1;
}
