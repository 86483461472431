.grid {
  display: grid;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.header_title_icon {
  margin-bottom: 2px;
  width: 50px;
  height: 40px;
}

.loading_skeleton {
  height: 20px;
  width: 120px;
}

.datepicker_container {
  min-width: 120px;
}

.table_container {
  min-width: 1050px;
}
.table_item {
  border-bottom: 1px solid var(--app-border-grey);
  flex: 1;
}

.table_item_text {
  letter-spacing: 0.5px;
  flex: 1;
  word-break: break-all;
}

.table_header {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid var(--app-border-grey);
}

.table_header_text {
  letter-spacing: 0.5px;
  font-weight: bold;
  word-break: break-all;
}

.table_empty {
  display: flex;
  justify-content: center;
  align-content: center;
  color: grey;
  font-weight: 500;
}

.title_text {
  font-size: 18px;
  font-weight: bold;
  padding: 20px 0px 0px 40px;
  background-color: #fbfafa;
}

.error_message {
  color: red;
  font-size: 14px;
  font-weight: 500;
}

.label_asterisk {
  color: red;
  margin-left: 5px;
}

.filter_container {
  display: flex;
  padding: 20px 40px;
  gap: 20px;
  background-color: #fbfafa;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.filter_item {
  width: 220px;
}

.paginated_list_table_loading_container {
  padding: 0px 20px;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.header_container {
  color: var(--text-primary);
  flex: 1;
  letter-spacing: 0.5px;
}

.header_close_button {
  width: 35px;
  height: 35px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background: none;
  border: none;
  color: #1871fe;
}

.header_close_button:hover {
  opacity: 0.7;
}

.header_close_button_icon {
  font-size: 2rem !important;
}

.header_title_container {
  align-items: center;
  gap: 15px;
}

.header_title_icon_container {
  border: 4.5px solid var(--order-secondary);
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_title_container > p {
  font-weight: bold;
  font-size: 1.2rem;
}

.footer_container {
  gap: 20px;
  color: var(--text-primary);
  letter-spacing: 0.5px;
}

.footer_container > button {
  background: none;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 3px;
  padding: 8px 25px;
  letter-spacing: inherit;
}

.footer_container > button:disabled {
  opacity: 0.2;
}

.footer_container > button:hover {
  opacity: 0.7;
}

.footer_container > button:first-child {
  background-color: #eeeeee;
}

.footer_container > button:last-child {
  color: white;
  background-color: var(--order-primary);
}
.modal {
  position: absolute;
  top: 0px;
  right: 0px;
  transform: none !important;
  min-width: 700px;
  max-width: none;
  width: 80%;
  height: 100%;
  margin: 0;
}

.body_container {
  display: flex;
  flex: 1;
  letter-spacing: 0.5px;
  color: var(--text-primary);
}

.user_input_container {
  padding: 5px 40px;
  margin: 1em;
  font-size: 14px;
}

.user_input {
  align-items: center;
}

.work_center_input {
  min-width: 206px;
}

.bold_text {
  font-weight: bold;
}

.info_label {
  margin-left: 30px;
}

.info_value {
  margin-left: 5px;
}

.attribute_label {
  font-weight: bold;
  font-size: 0.85rem;
}

.label_asterisk {
  color: red;
  margin-left: 5px;
}

.list_table_container {
  padding: 5px 40px;
  background-color: #fbfafa;
}

.skeleton_header {
  margin-top: 10px;
}
