.title {
  padding: 12px 24px 12px 24px;
  font-weight: bold;
  font-size: 24px;
  color: var(--text-primary);
  -webkit-font-smoothing: antialiased;
}

.content_container {
  display: flex;
  flex-direction: column;
}

.cancel_button_group {
  display: inline-flex;
  width: 85px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.cancel_button_icon {
  margin-bottom: 3px;
}

.cancel_button_text {
  color: #4A90FF;
  font-size: 18px;
}

.submit_button_container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.list_view_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.main_text {
  font-size: 1.2em;
}

.sub_text {
  margin-top: 1.3em;
  font-size: 0.8em;
  font-weight: 500;
  color: var(--text-secondary);
}

.outer_attribute_container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

.left_attribute_container {
  display: flex;
  flex: 4;
  flex-direction: column;
  align-items: center;
}

.right_attribute_container {
  display: flex;
  flex: 7;
  flex-direction: column;
  align-items: center;
}

.input_container {
  min-height: 110px;
}

.input_error_text {
  font-size: 14px;
  margin-top: 5px;
  margin-left: 10px;
  max-width: 240px;
  color: red;
  letter-spacing: 0.5px;
  overflow-wrap: break-word;
}
