.modal-content {
  flex: 1;
}

.reprint-modal-header {
  padding: 20px 30px;
}

.reprint-modal-body {
  padding: 0 30px;
}
