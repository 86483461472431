.container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.bold_text {
  font-weight: 600;
  margin-bottom: 5px;
}

.clickable_text {
  cursor: pointer;
  color: var(--app-blue);
  width: fit-content;
  margin-top: 4px;
}

.container_identifier {
  height: 24px;
}

.label {
  display: flex;
}

.required_asterisk {
  user-select: none;
  font-weight: 700;
  font-size: var(--font-size-small);
  color: var(--status-red);
  margin-left: 3px;
}
