.group_container {
  border: 1px solid var(--app-border-grey);
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 100%;
}

.group_label {
  position: absolute;
  background-color: white;
  color: #92929d;
  font-size: 14px;
  top: -12px;
  left: 15px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: "500";
}
