.container {
  width: 100%;
  height: 100%;
  max-width: 400px;
}

.image {
  width: 30%;
  justify-self: center;
  align-self: center;
}

.top_section {
  display: flex;
  height: 70%;
  padding-bottom: 5px;
}

.top_left_container {
  width: 100%;
  padding-left: 20px;
  padding-right: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.top_title {
  font-size: 20px;
  font-weight: bold;
  padding-top: 20px;
  word-break: break-all;
  height: 50%;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.top_metric_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.top_metric_group {
  height: 30%;
  display: flex;
  font-size: 12px;
  line-height: 14px;
  padding-right: 20px;
}

.top_metric_data {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  padding-left: 5px;
  padding-right: 5px;
  word-break: break-all;
}

.bottom_section {
  border-top: 2px solid lightgray;
  display: flex;
  padding-top: 5px;
  justify-content: space-around;
  text-align: center;
  height: 30%;
  font-size: 12px;
}
.bottom_data_group {
  width: 33%;
  font-size: 12px;
  padding: 0px 5px 0px 5px;
}
.bottom_data_content {
  margin: 0;
  margin-top: 5px;
  line-height: 12px;
  word-break: break-all;
  font-weight: bold;
  letter-spacing: -1px;
}
