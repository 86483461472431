.container {
  display: flex;
  flex-direction: row;
}
.search_type_container {
  width: 100px;
}

.search_type_textarea {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.search_container {
  width: 250px;
}

.search_textarea {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
