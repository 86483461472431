.flex_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.table_controls_container {
  display: flex;
  flex-direction: row;
  color: var(--app-blue);
  justify-content: space-between;
}

.blue_text {
  font-size: 14px;
  color: var(--app-blue);
}

.blue_text:hover {
  background-color: var(--xemelgo-light-blue);
  border-radius: 3px;
}

.sort_button {
  margin-left: 12px;
  padding-left: 6px;
  padding-right: 0px;
}

.expand_button_area {
  min-width: 150px;
  text-align: right;
}

.expand_button {
  height: 100%;
  display: flex;
  align-items: center;
  float: right;
}

.expand_icon {
  scale: 0.8;
}
