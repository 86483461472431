.modal {
  min-width: 600px;
}

.modal_title {
  font-size: var(--font-size-xlarge);
  font-weight: bold;
  padding: 10px;
}

.sections_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
}

.field_container {
  padding-bottom: 10px;
  width: 225px;
}

.separator {
  border-right: 1px solid #ccc;
  height: auto;
  margin: 2px 10px;
}
