.summary_tile_holder {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  height: 7em;
  min-height: 135px;
  max-height: 160px;
  margin: 0em 1em 1em 0em;
}

.extraHeight {
  height: 10em;
}
