.popover_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 30px;
  min-height: 30px;
  max-height: 500px;
}

.children_container {
  position: relative;
  z-index: 99;
  display: flex;
  flex-direction: column;
  font-weight: 600;
}

.tooltip_box {
  display: none;
}

.tooltip_box_visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.arrow {
  position: absolute;
  z-index: 99;
  background-color: white;
  border: 5px solid var(--app-border-grey);
  margin-top: 8px;
  border-width: 1px 1px 0 0;
  width: 15px;
  height: 15px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.tooltip_text_box {
  position: absolute;
  z-index: 99;
  margin-top: 50px;
  background-color: white;
  width: 90%;
  padding: 0.5em;
  box-shadow: 0.1em 0.2em 0.5em 0.1em var(--text-primary)42;
  border-radius: 0.2em;
  color: var(--text-primary);
  font-size: 1em;
  white-space: normal;
  word-break: break-all;
}
