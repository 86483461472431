.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
  gap: 20px;
}

.title_container {
  gap: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.title {
  font-weight: bold;
  font-size: 20px;
}

.sub_title {
  font-weight: 500;
}

.metric_container {
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
}

.overview_title {
  font-weight: bold;
  border-bottom: 2px solid var(--app-border-grey);
}

.each_metric {
  padding: 20px 60px;
  text-align: center;
}

.each_metric > p:last-of-type {
  font-weight: bold;
  font-size: 20px;
}

.bar_graph_container {
  width: 900px;
  height: 100%;
}

.action_button_container {
  justify-content: flex-end;
  align-items: center;
}

.action_button,
.action_button:hover {
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  background-color: var(--app-blue);
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
}

.action_button:hover {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.6);
}

.empty_list_container {
  justify-content: center;
  align-items: center;
  padding: 200px 0px;
}

.empty_list_text {
  font-weight: bold;
  color: var(--text-secondary);
}

.loading_icon {
  flex: 1;
  height: 24px;
}

.table_header {
  flex: 1;
  border-bottom: 1px solid var(--app-border-grey);
}

.table_column {
  flex: 1;
  padding: 10px;
  align-items: flex-start;
  gap: 5px;
}

.table_header_text {
  font-weight: bold;
}

.table_item {
  border-bottom: 1px solid var(--app-border-grey);
}

.table_item_text {
  color: var(--app-blue);
  cursor: pointer;
  letter-spacing: 0.5px;
}

.table_item_text:hover {
  text-decoration: underline;
}
