.modal_title {
  margin-top: 1rem;
  margin-left: 1.5rem;
  font-size: 26px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0.06px;
  text-align: left;
}

.modal_body {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0;

  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.045px;
  text-align: left;
}

.grid {
  display: grid;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.input_group_list {
  grid-template-columns: repeat(2, 47.5%);
  column-gap: 5%;
  margin-top: 10px;
  row-gap: 20px;
}

.label_asterisk {
  color: var(--status-red);
  margin-left: 5px;
}

.bom_table_list {
  flex: 1;
  padding: 20px 0;
  gap: 20px;
}

.group_onboarding_section {
  flex: 1;
}

.group_onboarding_rows {
  flex: 1;
  gap: 20px;
  overflow-y: auto;
  max-height: 300px;
}

.group_onboarding_count {
  width: "8%";
  align-items: center;
}

.group_onboarding_container {
  width: 35%;
  padding: 0px 8px;
}

.group_onboarding_row_container {
  display: flex;
  padding: 20px 0px;
  border-bottom: 1px solid #e2e2ea;
}

.group_onboarding_headers {
  border-bottom: 1px solid #e2e2ea;
}

.attribute_label {
  font-weight: bold;
  font-size: 0.85rem;
}
