.flex_column {
  display: flex;
  flex-direction: column;
}

.slide {
  gap: 20px;
  padding: 0px 5px;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}

.title {
  font-weight: bold;
  color: var(--text-primary);
}
