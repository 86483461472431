.small_action_container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.sort_header_btn {
  cursor: pointer;
  font-size: 12px;
  margin-right: 20px;
  color: var(--app-blue);
}

.sort_header_btn:hover {
  text-decoration: underline;
}

.header_collapse_btn {
  margin-top: -40px;
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;
  font-size: 12px;
  color: var(--app-blue);
}

.header_collapse_btn:hover {
  text-decoration: underline;
}

.datalist_container {
  padding-top: 10px;
  padding-bottom: 10px;
}
