.checkbox_container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.checkbox_label_text {
  font-weight: 500;
}
