.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  padding: 10px 0px;
}

.description_container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  gap: 5px;
  padding-bottom: 10px;
}

.font_bold {
  font-weight: bold;
  color: var(--text-primary);
}

.input_group {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  flex: 1;
}

.input_container {
  width: 50px;
  margin-left: 10px;
}

.label_text {
  font-weight: 500;
  color: var(--text-primary);
}

.critical_color {
  color: var(--status-red);
}

.warning_color {
  color: var(--status-yellow);
}

.input {
  text-align: center;
}

.save_button {
  background-color: var(--app-blue);
  color: white !important;
  font-weight: bold;
}
