.profile-fields .input-text,
.profile-fields .md-form label {
  color: var(--text-primary);
  background-color: rgba(0, 0, 0, 0);
  text-align: left;
}

.admin-field {
  color: var(--text-primary);
  text-align: left;
  font-size: 13.5px;
  padding-right: 7px;
  box-sizing: border-box;
  outline: none;
  font-weight: 100;
}

.checkbox {
  vertical-align: middle;
}

.md-form {
  margin-bottom: 0;
  padding-top: 10px;
  position: "relative";
}

.profile-fields .phone-input {
  height: 50px;
}

.profile-fields .phone-input ::placeholder {
  color: var(--neutral-primary);
}

.optional-text {
  font-weight: 400;
  color: var(--text-secondary);
  font-style: italic;
}

.optional-text-error {
  font-weight: 400;
  color: var(--status-red);
}

.error-message-group {
  display: block;
}

.error-message-text {
  text-align: center;
  color: var(--status-red);
  font-weight: bold;
}

.react-phone-number-input__icon {
  width: 2.15em !important;
  height: auto !important;
}
