.flex_row {
  display: flex;
  flex-direction: row;
}

.table_column {
  flex: 1;
  padding: 10px;
  align-items: flex-start;
  gap: 5px;
}

.table_item {
  border-bottom: 1px solid var(--app-border-grey);
  flex-grow: 1;
  position: relative;
}

.no_bottom_border {
  border-bottom: none;
}

.table_item_text {
  color: var(--app-blue);
  cursor: pointer;
  letter-spacing: 0.5px;
}

.table_item_text:hover {
  text-decoration: underline;
}

.auto_approved_text {
  position: absolute;
  right: 5px;
  top: 25%;
  color: var(--status-green);
}
