.react_journey__node {
  border: 7px solid var(--text-secondary);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: left;
  margin-top: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
  background-color: hsl(0, 16%, 91%);
  margin-bottom: .5em;
}

.react_journey__node a {
  color: #282c34;
  text-decoration: none;
  font-size: 20px;
  pointer-events: none;
}

.react_journey__node p {
  color: var(--text-primary);
  line-height: 20px;
  margin-top: 160%;
  width: 100px;
  left: -85%;
  position: absolute;
  overflow-wrap: break-word;
}

.react_journey__intermediate::after {
  content: "";
  display: inline-block;
  position: absolute;
  border: 4px solid var(--text-secondary);
  width: 80px;
  top: 45%;
  left: 42px;
  /*left: 69%;*/
}

.react_journey__done,
.react_journey__done_shipping,
.react_journey__done_current {
  background-image: url("../../img/whiteCheck.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position-y: 0.5em;
  background-position-x: 0.25em;
  background-color: var(--status-green);
  border: 7px solid var(--status-green);
  margin-bottom: .5em;
}

.react_journey__done::after {
  border: 4px solid var(--status-green);
}

.react_journey__done_shipping::after {
  border: 4px dashed var(--status-green);
}

.react_journey__done_current::after {
  border: 4px solid var(--text-secondary);
}

.react_journey__missing,
.react_journey__missing_shipping,
.react_journey__missing_current {
  background-image: url("../../img/missingIcon.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position-y: 0.5em;
  background-position-x: 0.15em;
  background-color: #fff;
  border: 7px solid #f44336;
  margin-bottom: 3.5em;
}

.react_journey__missing::after {
  border: 4px solid #f44336;
}

.react_journey__missing_shipping::after {
  border: 4px dashed #f44336;
}

.react_journey__missing_current::after {
  border: 4px solid var(--text-secondary);
}

.react_journey__misrouted,
.react_journey__misrouted_shipping,
.react_journey__misrouted_current {
  background-image: url("../../img/misroutedIcon.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position-y: 0.25em;
  background-position-x: 0.26em;
  background-color: #fff;
  border: 7px solid #f44336;
  margin-bottom: 3.5em;
}

.react_journey__misrouted::after {
  border: 4px solid #f44336;
}

.react_journey__misrouted_shipping::after {
  border: 4px dashed #f44336;
}

.react_journey__misrouted_current::after {
  border: 4px solid var(--text-secondary);
}

.react_journey__processing {
  background-image: none;
  background-color: white;
}

/* .react_journey__current {
  background-image: url('../../img/whiteCheck.png');
  background-repeat: no-repeat;
  background-size: auto;
  background-position-y: 0.5em;
  background-position-x: 0.15em;
  background-color: var(--status-green);
  border: 7px solid var(--status-green);
  z-index: 10;
}

.react_journey__current::after {
  border: 4px solid var(--text-secondary);
} */
