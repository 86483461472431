.modal_container {
  width: 840px;
  max-width: 960px !important;
}

.modal_body_container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  min-width: 720px;
}

.loading_message {
  font-style: italic;
}

.single_create_tab {
  display: flex;
  flex-direction: column;
}
