.action_bar_container {
  display: flex;
  background-color: #fafafa;
  padding: 10px 15px 8px 15px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  margin-bottom: 15px;
}

.action_bar_container p {
  margin-right: 20px;
  padding: 0;
}

.action_bar_container button {
  margin-right: 15px;
  padding: 0;
}

.action_bar_container button > p {
  display: inline-block;
}

.action_bar_container .icon_container {
  display: inline-block;
  position: relative;
  top: -2px;
}
