.modal_text {
  margin-bottom: 12px;
}

.location_dropdown {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin: 0 0 20px;
  width: 235px;
  font-weight: 600;
}

.dropdown_menu {
  max-height: 200px;
}
