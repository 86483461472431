/* These styles are to override the modal form styles */
.location-body {
  padding: 0;
}

.location-title {
  width: 100%;
}

.location-header {
  padding: 0;
}

.modal-content {
  max-height: none !important;
}
