.flex_column {
  display: flex;
  flex-direction: column;
}

.slide {
  gap: 5px;
  padding: 0px 5px;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}

.metric_label {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.metric_value {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.loading_icon {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 100%;
}
