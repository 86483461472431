ul {
  margin-right: 0.5em;
  justify-content: space-around !important;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.bold_text {
  font-weight: bolder;
  font-size: 1.1em;
}

.page {
  padding-left: 0.7em;
  padding-right: 0.7em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.active {
  font-size: 1em;
  color: #fff;
  background-color: rgb(66, 133, 244, 0.95);
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  border-radius: 0.1em;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.break {
  padding-left: 0.2em;
  padding-right: 0.2em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}
