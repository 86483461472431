.side_navigation_container {
  z-index: 2;
  border: none;
  background-color: white;
  position: fixed;
  top: 70px;
  left: 0px;
  width: 250px;
  height: 100%;
  padding-top: 20px;
  box-shadow: 1px 10px 8px -6px black;
  transition: width 500ms ease-in-out;
}

.side_navigation_container_collapsed {
  z-index: 2;
  border: none;
  background-color: white;
  position: fixed;
  top: 70px;
  left: 0px;
  width: 73px;
  height: 100%;
  padding-top: 20px;
  box-shadow: 1px 10px 8px -6px black;
  transition: width 500ms ease-in-out;
}

.side_navigation_container_test {
  z-index: 2;
  border: none;
  background-color: white;
  position: fixed;
  top: 90px;
  left: 0px;
  width: 250px;
  height: 100%;
}

.side_navigation_container_test_collapsed {
  z-index: 2;
  border: none;
  background-color: white;
  position: fixed;
  top: 90px;
  left: 0px;
  width: 74px;
  height: 100%;
}

.main_app_component_container {
  position: fixed;
  height: calc(100% - 63px);
  width: calc(100% - 250px);
  left: 250px;
  top: 65px;
  overflow: auto;
  box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  transition-property: width left;
  transition-duration: 500ms;
}

.main_app_component_container_collapsed {
  position: relative;
  height: calc(100% - 65px);
  width: calc(100% - 73px);
  left: 73px;
  top: 65px;
  overflow: auto;
  box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  transition-property: width left;
  transition-duration: 500ms;
}

.main_app_component_container_test {
  position: relative;
  height: calc(100% - 86px);
  width: calc(100% - 250px);
  left: 250px;
  top: 91px;
  overflow-y: scroll;
  overflow-x: auto;
  box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  transition-property: width left;
  transition-duration: 500ms;
}

.main_app_component_container_test_collapsed {
  position: relative;
  height: calc(100% - 86px);
  width: calc(100% - 73px);
  left: 73px;
  top: 91px;
  overflow-y: scroll;
  overflow-x: auto;
  box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
}

.main_app_component_full_screen_container {
  height: 100%;
  box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  transition-property: width left;
  transition-duration: 500ms;
}

.header_fullscreen_container_test {
  height: 30px;
}

.header_component_container {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  background-color: white;
  z-index: 3;
}

.header_component_container_test {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 86px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: white;
  z-index: 3;
}

.render_component_container {
  background-color: transparent;
  padding: 0px 20px 20px;
  display: inline-flex;
  min-width: 100%;
  flex-direction: column;
  min-height: calc(100% - 20px);
}

.banner_container {
  max-height: 100%;
  transition: max-height 1s ease-in-out;
  overflow: hidden;
  padding: 30px 20px 10px 20px;
}

.banner_container.banner_container_collapsed {
  max-height: 0px;
  height: 0px;
  transition: max-height 0.3s linear;
  padding: 10px 20px;
}

.nav_icon {
  height: 30px;
  width: 30px;
  min-width: 40px;
  margin-left: -7px;
  margin-bottom: 5px;
}

.tab_collapse_icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
