.status {
  margin-left: 5px;
  font-style: italic;
  font-size: 12px;
  display: inline-flex;
  flex-direction: row;
  min-height: 20px;
}
.img {
  position: relative;
  max-width: 100px;
  min-width: 100px;
  max-height: 100px;
  min-height: 100px;
  border-radius: 5px !important;
  left: 5px;
}

.details_default {
  margin-left: 10px;
  width: 100%;
  height: auto;
  min-height: 100px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.name {
  letter-spacing: 1px;
  display: flex;
  min-width: 80%;
  min-height: 55px;
  text-align: center;
  word-break: break-all;
  padding: 2px;
  height: auto;
  width: auto;
  justify-content: flex-start;
  font-weight: bolder;
  font-size: 1em;
}

.rfid_label {
  font-size: 12px;
}

.rfid_value {
  min-height: 40px;
  max-width: 180px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 12px;
  font-weight: bold;
}

.rfid_group {
  padding: 5px;
}

.bottom_group_large {
  width: 125px;
  max-width: 125px;
  align-self: flex-start;
  text-align: center;
}

.bottom_group_small {
  width: 100px;
  max-width: 100px;
  align-self: flex-start;
  text-align: center;
}

.bottom_group_title {
  font-weight: 200;
}

.bottom_group_data {
  font-weight: bold;
}

.details_location {
  margin: 10px;
  width: 100%;
  height: 80px;
  min-height: 80px;
  justify-content: space-around;
}

.name_location {
  letter-spacing: 1px;
  display: flex;
  min-width: 80%;
  text-align: center;
  word-break: break-all;
  padding: 5px;
  height: auto;
  width: auto;
  justify-content: center;
  margin: 10px;
  font-weight: bolder;
  font-size: 20px;
}

.velocity_title {
  font-size: 12px;
}

.velocity_value {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 16px;
  font-weight: bold;
}

.velocity_group {
  justify-content: center;
  align-items: center;
}

.order_number_label {
  font-size: 12px;
}

.order_number_value {
  min-height: 40px;
  max-width: 160px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 12px;
  font-weight: bold;
}

.flex_direction_row {
  display: flex;
  flex-direction: row;
}

.icon_button {
  min-width: 40px;
  min-height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 24px;
  border-radius: 3px;
  cursor: pointer;
  padding: 4px;
}

.icon_button:hover {
  color: white;
  background-color: var(--app-blue);
}

.filter_group {
  padding: 5px;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.filter_bar {
  height: 40px;
  margin-left: 5px;
  margin-right: 10px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid var(--app-border-grey);
  padding-left: 1em;
}

.list_view_status {
  margin-top: 4px;
  margin-bottom: 3px;
  padding: 3px 12px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  max-width: 87px;
  color: var(--app-white);
}
