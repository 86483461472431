.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.table_pager {
  align-self: flex-end;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
}

.table_pager_button {
  padding: 3px 0px;
  color: var(--app-blue);
  border: 1px solid var(--app-border-grey);
  border-radius: 4px;
  cursor: pointer;
}

.table_pager_button:hover {
  border-color: var(--app-blue);
  color: var(--app-blue);
}

.table_pager_button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: #ccc;
  pointer-events: none;
}

.table_pager_button.disabled:hover {
  border-color: var(--app-border-grey);
  color: #ccc;
}

.disabledButton {
  color: var(--text-secondary);
}

.table_pager_number {
  margin: 0px 20px;
  letter-spacing: 0.5px;
}

.table_pager_size_text {
  margin: 0px 15px;
}

.table_pager_size_selector {
  background: transparent;
  background-image: none; /* set to an image */
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  padding: 0.25em 0.25em;
  cursor: pointer;
  border: 0px solid white;
  outline: 0px white;
  margin-right: 40px;
}

.table_pager_size_dropdown {
  color: var(--app-blue);
}

.table_pager_size_item {
  color: var(--text-primary);
}
