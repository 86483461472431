.upload_status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  color: var(--status-green);
}

.status_icon {
  position: relative;
  top: 1px;
  margin-right: 4px;
}

.clickable_text {
  color: var(--app-blue);
  font-weight: bold;
}

.status_message {
  display: flex;
  flex-direction: row;
}
