.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: var(--app-blue);
  font-size: 14px;
  height: 40px;
  border-radius: 3px;
}

.button:hover {
  background-color: var(--app-lightblue);
}
