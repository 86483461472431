.table_container {
  margin: 8px 0;
  max-height: 270px;
  overflow-y: scroll;
}

.header_container {
  font-weight: 600;
  font-size: 14px;
  padding: 10px 0 10px 12px;
  border-bottom: 1px solid var(--app-border-grey);
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  column-gap: 6px;
}

.table_header {
  flex: 1;
}

.items_container {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.table_row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--app-border-grey);
  padding: 10px 0 10px 12px;
  column-gap: 6px;
}

.table_cell {
  flex: 1;
  word-break: break-all;
}

.button_text {
  font-size: 14px;
  font-weight: 600;
  color: var(--app-blue);
  cursor: pointer;
  width: fit-content;
  user-select: none;
  float: right;
  margin-right: 8px;
}

.qr_code_tooltip {
  min-width: min-content;
}
