.info_content_label {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.410612px;
  padding-bottom: 5px;
}

.input_container {
  width: 235px;
}

.text_input_container {
  padding-bottom: 25px;
}

.asterisk {
  color: var(--status-red) !important;
}

.dropdown_input_container {
  padding-bottom: 15px;
  width: 225px;
}
