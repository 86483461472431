.flex_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--app-border-grey);
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 2px 9px -4px rgba(0, 0, 0, 0.5);
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 700;
  color: var(--text-primary);
  min-height: 250px;
}

.content_container {
  display: flex;
  column-gap: 30px;
}

.calendar_container {
  min-height: 174px;
}

.quick_filter_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.date_time_picker_container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.quick_filter_options {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 10px;
}

.quick_filter_button {
  border-radius: 3px;
  border: 1px solid var(--app-border-grey);
  width: 100%;
  text-align: center;
  padding: 3px 14px;
  font-weight: 500;
  cursor: pointer;
}

.selected_filter {
  border-color: var(--app-blue);
  color: var(--app-blue);
  background-color: var(--xemelgo-light-blue);
}

.buttons_container {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  align-items: center;
}

.clickable_text {
  color: var(--app-blue);
  margin-right: 14px;
  cursor: pointer;
  padding: 4px 0px;
}

.blue_button {
  border-radius: 3px;
  background-color: var(--app-blue);
  color: white;
  cursor: pointer;
  padding: 4px 13px;
}

.dropdown_container {
  margin-left: 4px;
  width: 100px;
}
