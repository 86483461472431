.status_modal {
  background-color: var(--status-green);
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 3px;
  padding: 6px 18px;
  width: fit-content;
  margin: auto;
  color: white;
}

.error {
  background-color: var(--status-red);
}

.message_text {
  margin-top: 1px;
  margin-left: 10px;
  font-weight: 500;
}

.centered_popup_container {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  z-index: 1000;
}
