.form_group {
  padding-bottom: 0.3em;
}

.login_form {
  text-align: "initial";
}

.input_field_container {
  position: relative;
}

.show_password_button {
  position: absolute;
  right: 0;
  top: 0.5em;
  color: var(--app-white);
}

.password_input,
.password_input:focus {
  color: var(--app-white);
}

.password_form_button_container {
  display: flex;
  justify-content: center;
}

.change_password_button {
  text-transform: none;
  letter-spacing: 0.04rem;
  margin-left: 0.5em;
  margin-right: 1em;
}
