.checkbox_outer {
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 1px;
  border-radius: 2px;
  border: thin solid var(--app-border-grey);
}

.checkbox_checked {
  height: 100%;
  width: 100%;
  background-color: var(--app-blue);
  border-radius: 2px;
}

.checkbox_unchecked {
  height: 100%;
  width: 100%;
  background-color: var(--app-white);
  border-radius: 2px;
}

.rounded {
  border-radius: 100%;
}
