.group {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}

.color_indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.color_icon {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 100%;
}

.color_label {
  font-size: 14px;
  font-weight: 500;
}
