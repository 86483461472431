.more_options_icon {
  height: 100%;
  scale: 1.2;
  margin: 0 6px;
}

.options_container {
  column-gap: 12px;
  justify-content: space-between;
  align-items: stretch;
}

.white_button {
  background-color: var(--app-white);
  border: 1.5px solid var(--app-blue);
  color: var(--app-blue);
}

.white_button:hover {
  background-color: var(--xemelgo-light-blue);
  color: var(--app-blue);
}

.free_text_search_container {
  flex: 1;
}

.free_text_search_input {
  padding: 7px 30px;
}

.export_icon {
  margin-right: 10px;
}

.export_button {
  font-weight: 700;
}

.button {
  text-align: center;
  border-radius: 3px;
  padding: 5px 19px;
}

.blue_button {
  font-weight: 700;
  background-color: var(--app-blue);
  color: var(--app-white);
}

.more_options_button {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0;
}

.more_options_dropdown {
  margin-right: 32px;
}
