.search_result_location_container {
  padding: 20px 0 12px;

  border-bottom: 1px solid var(--app-border-grey);
}

.search_result_location_container:hover {
  background-color: #f7f7f7;
}

.location_header {
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  text-align: center;
  align-items: center;
}

.search_results_close_button {
  margin-left: auto;
  cursor: pointer;
  color: var(--app-blue);
  padding: 3px;
  border-radius: 100%;
}

.search_result_header {
  border-bottom: 1px solid var(--app-border-grey);
  padding: 15px 10px;
  align-items: center;
}

.search_result_title {
  font-size: 20px;
  font-weight: bold;
  color: var(--text-primary);
  margin-left: 10px;
}

.location_result_label {
  font-weight: bold;
  font-size: 16px;
}

.location_point_icon {
  justify-content: center;
  align-items: center;
  width: 25px;
  color: var(--app-blue);
}

.search_count_text {
  color: var(--text-secondary);
  margin-left: 8px;
  font-weight: 600;
}

.table_item {
  border-bottom: 1px solid var(--app-border-grey);
  margin: 0px 26px;
}

.paginator {
  padding: 0px;
  margin-right: 14px;
}
