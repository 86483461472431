.flex_column {
  display: flex;
  flex-direction: column;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.text_area_background {
  background-color: var(--app-offwhite);
}

.skeleton_loading_container {
  padding: 10px 20px;
  flex: 1;
  justify-content: space-between;
}

.skeleton_loading_container:not(:last-child) {
  border-bottom: 1px solid var(--app-border-grey);
}

.skeleton_loading_label_container {
  flex: 0.4;
  align-items: flex-start;
  width: 100%;
  gap: 5px;
}

.skeleton_loading_label_main {
  width: 100%;
}

.skeleton_loading_label_sub {
  width: 80%;
}

.skeleton_loading_status_container {
  flex: 0.25;
}

.dropdown_no_results {
  text-align: center;
  color: var(--app-lightgrey);
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 400;
  word-break: break-all;
  margin: auto;
  min-height: 298px;
}

.result_item_container {
  padding: 20px;
}

.result_item_container:hover {
  background-color: var(--app-offwhite);
}

.result_item_container:not(:last-child) {
  border-bottom: 1px solid var(--app-border-grey);
}

.label_container {
  gap: 5px;
}

.text {
  font-size: 14px;
}

.title_text {
  font-size: 18px;
}

.bold {
  font-weight: bold;
}

.status_container {
  text-align: center;
  justify-content: center;
  justify-self: flex-end;
  margin-left: auto;
  flex-wrap: nowrap;
  gap: 5px;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 25px;
  height: 25px;
}

.status_text {
  font-size: 12px;
  font-weight: 600;
  color: var(--app-white);
}
