.history_table_container {
  padding: 20px;
}

.table_container {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.header_container {
  background-color: var(--app-blue);
  display: flex;
  flex-direction: row;
  color: var(--app-white);
  padding: 5px 5px 5px 10px;
  font-size: 0.9em;
  font-weight: 600;
}

.header_style {
  width: 100%;
}

.table_title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.event_row {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
  background-color: #ffffff;
  color: #4c4c4c;
  border: 1pt solid #f6f6f6;
  padding: 5px 5px 5px 10px;
}

.event_row_text {
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.searchbar_pdfbutton_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.searchbar_container {
  position: relative;
  flex: 5;
  margin-right: 10px;
}

.searchbar_icon {
  color: var(--text-secondary);
  position: absolute;
  left: 10px;
  top: 8px;
}

.searchbar {
  width: 100%;
  border: var(--app-border-grey);
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  height: 35px;
  padding-left: 30px;
  padding-top: 5px;
}

.pdfbutton_container {
  width: 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: var(--app-blue);
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  color: var(--app-blue);
  cursor: pointer;
}

.pdfbutton_text {
  font-weight: 600;
}

.export_icon {
  margin-right: 5px;
}

.status {
  display: inline-block;
  font-size: 12px;
  margin: 2px;
  padding: 3px 12px;
  border-radius: 15px;
  color: var(--app-white);
}

.status_text {
  margin-top: 0.1em;
  font-weight: 600;
}

.table_item_text_clickable {
  color: var(--app-blue);
  cursor: pointer;
}
.table_item_text_clickable:hover {
  text-decoration: underline;
}
