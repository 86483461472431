.truncated {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hidden_comment {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.expand_text {
  color: var(--app-blue);
  cursor: pointer;
  user-select: none;
}

.expanded_text_container {
  min-width: 300px;
  max-height: 300px;
  overflow: auto;
}
