
.groups_container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.table_container {
  margin-top: 5px;
}

.selected_table_item {
  background-color: var(--xemelgo-light-blue);
}
