.view_buttons_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
}

.view_text {
  font-size: 15px;
  font-weight: 500;
}

.white_button {
  background-color: var(--app-white);
  border: 1.5px solid var(--app-blue);
  color: var(--app-blue);
}

.white_button:hover {
  background-color: var(--xemelgo-light-blue);
  color: var(--app-blue);
}

.button {
  text-align: center;
  border-radius: 3px;
  padding: 0 18px;
  min-width: 82px;
}

.asset_type_button {
  padding: 0;
  width: 118px;
}

.selected {
  font-weight: 700;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: var(--xemelgo-light-blue);
  color: var(--app-blue);
}

.unselected {
  padding-top: 5px;
  padding-bottom: 5px;
  color: var(--text-primary);
  border: 1.5px solid var(--app-border-grey);
}
