.status_tile {
  position: relative;
  padding: 5px 20px;
  flex: 0 0 calc(50% - 10px);
  border: 1.5px solid var(--app-border-grey);
  border-radius: 4px;
  cursor: pointer;
}

.status_tile:hover {
  background-color: #f7f7f7;
}

.status_title_color_indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 7px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: var(--app-border-grey);
}

.status_tile_hover {
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border: 2px solid transparent;
  border-radius: 4px;
}

.status_tile_title {
  letter-spacing: 0.5px;
  color: var(--text-primary);
}

.status_tile_subtitle {
  font-weight: bold;
  line-height: 21px;
  font-size: 18px;
  padding: 3px 0px;
}
