.backdrop {
  opacity: 0.5 !important;
}

.dialog_base {
  -webkit-transform: none !important;
  transform: none !important;
}

.header_base {
  color: var(--text-primary);
  padding: 20px;
  font-weight: bold;
  /* the following  css is to override the style made to the Modal from UserModal.css */
  margin: 0px !important;
}

.content_base {
  min-height: 30vh;
}

.body_base {
  overflow: hidden;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.footer_base {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 20px;
  border-top: 1px solid var(--app-border-grey);
  margin: 0px !important;
  gap: 20px;
}

.button_base {
  background-color: var(--app-offwhite);
  color: var(--text-primary);
  padding: 6px 25px;
  border-radius: 3px;
  font-weight: 500;
}

.button_base:hover {
  opacity: 0.8;
}
