.title_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
  align-items: center;
  padding: 0px 26px;
}

.body_container {
  padding: 26px 35px 30px 35px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  background: #fbfafa;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.button {
  border-radius: 4px;
  padding: 8px 15px;
  cursor: pointer;
}

.discard_button {
  background-color: #eeeeee;
}

.create_button {
  margin-left: 10px;
  background-color: var(--app-blue);
  color: var(--app-white);
}

.close_button {
  cursor: pointer;
}

.input_group {
  flex-wrap: wrap;
  row-gap: 30px;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 15px;
}

.input_container {
  font-weight: bold;
  width: 200px;
}

.section_container {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 35px;
}

.add_detector_button {
  width: 170px;
  align-self: end;
  cursor: pointer;
  font-weight: normal;
  color: var(--app-blue);
  text-align: right;
}

.top_separator {
  border-top: 1px solid var(--app-border-grey);
}
