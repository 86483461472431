.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.side_filter_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter_group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.filter_container {
  width: 100%;
}

.filter_group_title {
  font-weight: 500;
  font-size: 14px;
  color: var(--text-secondary);
}

.filter_group_filter {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.filter_title {
  color: var(--text-secondary);
  font-size: 12px;
  font-weight: 500;
}
