.close_icon {
  cursor: pointer;
  stroke: var(--app-blue);
  stroke-width: 2px;
  justify-content: flex-end;
}

.header_container {
  display: flex;
  flex-direction: row;
  padding: 4px 10px 10px 30px;
  border-bottom: 1.6px solid var(--app-border-grey);
  justify-content: space-between;
}

.header_title_container {
  padding-top: 20px;
  display: flex;
  align-items: center;
  width: 100%;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  padding: 2px;
  margin-right: 12px;
}

.header_title {
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 23px;
  color: var(--text-primary);
}

.header_additional_message {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-left: 24px;
}

.close_button {
 float: right;
 padding-right: 1%;
}

.success {
  color: var(--status-green);
}

.error {
  color: var(--status-red);
}
