.header_row {
  padding: 0 18px;
}

.item_row {
  padding: 0 18px;
  align-items: center;
}

.paginator {
  padding: 2px 0 10px;
}
