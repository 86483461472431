.swiper_container {
  min-width: 0;
}

.detail_image {
  max-width: 98%;
  max-height: 100%;
}

.image_container {
  margin: auto;
  width: 96%;
  display: flex;
  justify-content: center;
  height: 200px;
}

.skeleton {
  margin-bottom: 6px;
}
