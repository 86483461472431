.header_text {
  font-weight: bold;
}

.header_subtext {
  font-size: 16px;
  margin-top: 10px;
}

.label {
  font-weight: bold;
}

.content {
  display: flex;
  gap: 30px;
  flex-direction: column;
  min-height: 500px;
  max-height: 500px;
  padding-top: 10px;
}

.location_category_list_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.location_category_list {
  display: flex;
  flex-direction: row;
  gap: 10%;
}

.locations_list_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.free_text_search_container {
  width: 40%;
}

.locations_list {
  min-height: 340px;
  max-height: 340px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.save_button {
  background-color: var(--app-blue);
  color: white !important;
  font-weight: bold;
}
