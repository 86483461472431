.table_header {
  border-bottom: 1px solid var(--app-border-grey);
  flex: 1;
  gap: 10px;
}

.table_column {
  flex: 1;
  padding: 10px 0px;
  align-items: flex-start;
  gap: 5px;
}

.table_header_text {
  font-weight: bold;
}

.table_header_text_active {
  color: var(--app-blue);
  cursor: pointer;
}

.table_header_text_active:hover {
  text-decoration: underline;
  color: var(--app-blue);
}
