.filter_bar {
  height: 40px;
  border-radius: 3px;
  width: 100%;
  border: 1px solid var(--app-border-grey);
  font-size: 1rem;
  color: var(--neutral-primary);
  padding-left: 2em;
  margin-right: 10px;
  padding-right: 10px;
  text-overflow: ellipsis;
}

.filter_bar_icon {
  position: absolute;
  font-size: 1rem;
  margin-left: 10px;
  color: var(--neutral-primary);
  padding-bottom: 3px;
}

.filter_bar_container {
  display: flex;
  align-items: center;
  width: 350px;
  padding-left: 10px;
  padding-top: 10px;
}

.filter_title {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.25;
  color: var(--text-primary);
}

.hide_filter {
  font-size: 12px;
  align-self: center;
  color: var(--app-blue);
  cursor: pointer;
}

.filter_title_container {
  display: flex;
  justify-content: space-between;
}

.date_container {
  margin-top: 10px;
}

.main_container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex: 1;
}

.left_main_container {
  border-right: 1px solid var(--app-border-grey);
  padding: 15px;
  width: 230px;
}

.create_button {
  height: 50px;
  width: 100%;
  text-align: center;
  background-color: var(--app-blue);
  justify-content: center;
  display: flex;
  align-items: center;
  color: var(--app-white);
  margin-bottom: 10%;
  border-radius: 0.15625rem;
  cursor: pointer;
}

.create_button:hover {
  color: var(--app-white);
  box-shadow: 0px 3px 9px 0px var(--neutral-primary);
}

.side_filter_input {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
}

.main_view_container {
  width: 100%;
  padding: 20px 20px 60px 20px;
}

.create_modal_header {
  padding: 20px 0px 10px 35px;
}

.create_modal_body {
  padding: 10px 35px 10px 35px;
}

.create_modal_footer {
  display: flex;
  justify-content: space-between;
  width: 35%;
  padding-right: 35px;
}

.create_input_group_error {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.progress_bar_container {
  display: flex;
  height: 175px;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 35px 0 35px;
}

.loading_container {
  height: 175px;
}

.create_input_group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}