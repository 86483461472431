.create_button,
.create_button:hover {
  text-align: center;
  flex: 1;
  background-color: var(--app-blue);
  border-radius: 3px;
  color: var(--app-white);
  padding: 10px;
}

.create_button:hover {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.6);
}

.create_button:active {
  opacity: 0.9;
}
