.modal_container * {
  letter-spacing: 0.2px;
}

.flex_row {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}

.modal_dialog {
  transform: none !important;
  overflow-y: hidden !important;
  font-weight: 500;
  min-width: min-content;
}

.modal_top_container {
  padding: 26px 24px 12px;
  margin-bottom: 10px;
}

.modal_button_container {
  padding: 10px 24px;
  display: flex;
  flex-direction: row-reverse;
  align-self: end;
  width: 100%;
  border-top: 1.5px solid var(--app-border-grey);
}

.button {
  width: 104px;
  padding: 6px 0px;
  border-radius: 3px;
}

.blue_button {
  background-color: var(--app-blue);
  color: var(--app-white);
}

.grey_button {
  background-color: var(--app-offwhite);
  margin-right: 12px;
}

.title_text {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 8px;
}

.bold_text {
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 6px;
}
