.modal-content {
  flex: 1;
}

.schedule_work_order-modal-header {
  padding-left: 40px;
  padding-right: 40px;
  margin: 0 !important;
}

.schedule_work_order-modal-title {
  flex: 1;
}

.schedule_work_order-modal-body {
  padding: 0px;
  display: flex;
  overflow-x: scroll;
}

.schedule_work_order-modal-footer {
  padding-left: 30px;
  padding-right: 30px;
}
