.filter_bar_container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter_bar_icon {
  position: absolute;
  font-size: 1rem;
  margin-left: 10px;
  color: var(--neutral-primary);
  padding-bottom: 3px;
}

.filter_bar {
  height: 40px;
  border-radius: 3.8px;
  width: 100%;
  border: 1px solid var(--app-border-grey);
  font-size: 1rem;
  color: var(--neutral-primary);
  padding-left: 2em;
  text-overflow: ellipsis;
  /* Text */
  font-family: "Avenir", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--text-secondary);
}
