.edit-schedule-header {
  display: flex;
  flex-direction: column;
  margin-bottom: -30px;
  margin-left: 0;
  padding: 20px;
}

.edit-schedule-title {
  font-weight: 600;
  padding: 10px 0px 10px 0;
}

.edit-schedule-body {
  padding: 20px;
  min-height: 200px;
  width: 500px;
}
