.floor-plan-map.asset-floor-plan {
  overflow: hidden;
  width: auto;
  max-height: 20em;
  margin: 0 3em;
}

.floor-plan-map.asset-floor-plan .interactive-image {
  max-height: 20em;
  height: initial;
  width: initial;
}
