.flex_direction_row {
  display: flex;
  flex-direction: row;
}

.display_block {
  display: block;
}

.cell {
  box-shadow: 0px 2px 4px var(--text-secondary);
  padding: 1.4rem 1.2rem 0rem 1.2rem;
  margin-bottom: 1em;
  width: 95%;
  min-height: 62px;
  margin-left: 1em;
  color: rgb(52, 52, 52);
  position: relative;
  border-radius: 0.3em;
  min-width: 350px;
}

.cell:hover {
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.55);
}

.statusIndicatorImage {
  width: 10px;
  height: 10px;
}

.row {
  margin-top: 0.5em;
  justify-content: space-between;
  display: flex;
  flex-wrap: nowrap;
}

.statusFlags_container {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  display: flex;
  flex-direction: row;
}

.alert_icon_container {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-right: 0.6em;
}

.alert_icon {
  height: 2.3em;
  width: 2.3em;
  padding: 0;
}

.status_text {
  margin-left: 0.45em;
  font-weight: 600;
  font-style: italic;
}

.data_item_div {
  margin-left: 0.3em;
  margin-right: 0.3em;
  font-size: 1em;
  overflow: visible;
  text-align: left;
  width: auto;
}

.data_item_value {
  overflow: hidden;
  padding-bottom: 1em;
}

a.detailsPageLink:link {
  color: rgb(52, 52, 52);
}

a.detailsPageLink:visited {
  color: rgb(52, 52, 52);
}

a.detailsPageLink:hover {
  color: Blue;
}

.data_item_title {
  font-size: 0.85em;
  font-weight: 700;
  font-style: italic;
  margin-bottom: 10px;
}

.recent_item_indicator {
  position: absolute;
  top: -11px;
  right: -18px;
  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 0.5em;
  margin-bottom: 0.2em;
}
