.list-group-item-highlight {
  font-weight: bolder;
  letter-spacing: 0.02em;
}

.add-new-item {
  color: #1e71d2;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
