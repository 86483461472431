.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.quick_action_button_group {
  border-bottom: 1px solid var(--app-border-grey);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.quick_action_button {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 2px;
}

.quick_action_button:hover {
  background-color: var(--app-border-grey);
}

.quick_action_button_title {
  color: var(--app-blue);
  font-weight: 500;
}
