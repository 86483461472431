.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.metric_group {
  flex: 1;
}

.swiper_container {
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex: 1;
  min-width: 360px;
}

.carousel_container {
  flex: 1;
  height: 100%;
}

.carousel {
  overflow: hidden;
  min-width: 100%;
  width: 0;
  min-height: 0;
  height: 100%;
}

.carousel_container > .carousel {
  padding-bottom: 50px;
  padding-top: 20px;
}

.carousel_navigation_button {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  color: var(--app-blue);
  background-color: white;
  border: 1px solid var(--app-border-grey);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);

  cursor: pointer;
}

.carousel_navigation_button:hover {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
}

.carousel_navigation_button:active {
  opacity: 0.7;
}

.carousel_navigation_button_disabled,
.carousel_navigation_button_disabled:active {
  opacity: 0.3;
  cursor: default;
}

.swiper_slide {
  display: grid;
  height: 100%;
  grid-column-gap: 10px;
  grid-row-gap: 20px;
}
