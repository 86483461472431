.container {
  display: flex;
  flex-direction: column;
}

.printer_component {
  margin-top: 12px;
}

.bold_text {
  font-weight: 600;
}
