.button_container {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 15px;
  margin-bottom: 25px;
}

.approval_report_button,
.approval_report_button:hover {
  text-align: center;
  background-color: var(--app-blue);
  border-radius: 5px;
  color: white;
  padding: 10px 20px;
  font-weight: 600;
}

.approval_report_button:hover {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.6);
}

.approval_report_button_icon {
  margin-right: 10px;
}

.standby_container > p {
  margin-bottom: 15px;
  font-weight: 700;
  display: inline-block;
}

.tooltip_icon_container {
  margin-left: 5px;
  color: var(--app-blue);
}

.tooltip_content_container {
  padding: 10px;
  font-size: 0.7rem;
}

.tooltip_content_container p:last-child {
  margin-top: 15px;
}

.modal_content {
  height: auto;
}

.modal_header {
  margin: 0;
  padding-left: 30px;
  padding-top: 25px;
}

.modal_header > div {
  font-weight: 700;
  font-size: 1.2rem;
}

.modal_body {
  padding: 20px 30px 30px 30px;
}

.action_button_container {
  justify-content: flex-end;
  align-items: center;
  align-self: flex-end;
}

.action_button,
.action_button:hover {
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  background-color: var(--app-blue);
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
}

.action_button:hover {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.6);
}
