.container {
  min-width: 160px;
  min-height: 100px;
  border-radius: 3px;
  padding: 25px 15px 15px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.info_icon_container {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23px;
  width: 23px;
}

.info_icon {
  cursor: pointer;
  color: var(--app-blue);
}

.info_icon:hover {
  opacity: 0.6;
}

.value_container {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.value_text {
  line-height: 1;
  font-weight: bold;
  font-size: 26px;
  text-align: center;
}

.label_container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 2;
  gap: 2px;
}

.label_text {
  line-height: 1.2;
  color: var(--text-primary);
  font-weight: 500;
  font-size: 12px;
  text-align: center;
}
