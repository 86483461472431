.tabbedSectionComponent-container.rule-tab-selection > .tabbedSectionComponent-tabs {
  width: 11em;
}

.tabbedSectionComponent-container.rule-tab-selection > .tabbedSectionComponent-tabs .tab {
  width: 100%;
}

.tabbedSectionComponent-container.rule-tab-selection > .tabbedSectionComponent-content {
  padding: 0;
  background-color: var(--app-white);
}

.alerts-page {
  display: flex;
  margin-top: 7%;
}

.alerts-settings-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1% 5% 1% 5%;
}

.last-alert {
  border-bottom: 0.125em solid var(--app-border-grey);
}

.switch-helper-text {
  font-size: 90%;
  color: var(--text-secondary);
}

.error-text {
  font-size: 90%;
  margin-top: 1.5%;
  color: var(--status-red);
}

.inline-bold {
  display: inline;
  font-weight: bold;
}

.time_input {
  align-items: start;
  margin-top: 5px;
  width: 120px;
  margin-right: 1em;
  border: 1px solid var(--app-border-grey);
  border-radius: 4px;
  padding: 4px 6px 2.5px 6px;
  font-weight: 500;
  font-size: 15px;
}

.alerts-settings-text {
  width: 40%;
}

.early-expiration {
  width: 120px;
  height: 50%;
  margin-right: 1em;
  border: 1px solid var(--app-border-grey);
  border-radius: 4px;
  padding: 6px 6px 4.5px 6px;
  font-weight: 500;
  font-size: 15px;
}

.early-expiration-container {
  align-self: flex-start;
  width: 50%;
  margin-left: 5%;
}

.button-container {
  margin: auto;
  margin-top: 4em;
  flex-direction: row;
  justify-content: space-between;
  width: 500px;
}

.input-error {
  border-color: var(--status-red);
}

.alerts_page_main_container {
  padding: 15px;
}

@media only screen and (min-width: 800px) {
  .alerts-page {
    display: block;
    margin-top: 5%;
  }
}
