.container {
  border-radius: 3px;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icon_button {
  border: 1px solid var(--app-border-grey);
  height: 100%;
  padding: 0px 10px;
  color: var(--text-primary);
}

.icon_button:hover {
  background-color: var(--app-lightblue);
  border-color: var(--app-blue);
}

.icon_button:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.icon_button:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.icon_button_selected {
  background-color: var(--app-lightblue);
  border-color: var(--app-blue);
  color: var(--app-blue);
}

.icon {
  width: 20px;
  height: 20px;
  color: inherit;
}
