.crud_table_title_optional {
  font-size: 0.8em;
  color: gray;
}

.crud_table_container {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.54);
  border-radius: 4px;
}

.basic_form_row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add_order_part_modal_title {
  text-align: left;
  padding-bottom: 0em;
  font-weight: bold;
}
