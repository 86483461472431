.table {
  width: 100%;
}

.table_row {
  vertical-align: top;
  border-bottom: 1.8px solid var(--app-border-grey);
}

.text_input_container {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.text_container {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 27px;
}

.main_text_container {
  display: flex;
  align-items: center;
}

.status {
  display: flex;
  align-items: center;
  height: 27px;
  padding: 2px 3px 0px 3px;
  color: var(--app-white);
  border-radius: 3px;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 0.8em;
  letter-spacing: 0.5px;
  font-weight: bold;
}

.number {
  margin-top: 13.5px;
}

.bold_text {
  font-weight: bold;
}

.number_header {
  font-weight: bold;
  width: 4%;
}

.add_item_container {
  margin-top: 15px;
  height: 45px;
  background-color: #eeeeee;
  width: 180px;
  align-items: center;
  display: flex;
  cursor: pointer;
  border-radius: 3px;
}

.add_item_icon {
  height: 1em;
  width: 1em;
  color: var(--app-white);
  background-color: var(--app-blue);
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 1em;
  display: flex;
}
