.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.table_header {
  flex: 1;
  border-bottom: 1px solid var(--app-border-grey);
}

.table_column {
  flex: 1;
  padding: 10px;
  align-items: flex-start;
  gap: 5px;
}

.table_header_text {
  font-weight: bold;
}

.table_item {
  flex-grow: 1;
  position: relative;
}

.bottom_border {
  border-bottom: 1px solid var(--app-border-grey);
}

.no_bottom_border {
  border-bottom: none;
}

.table_item_text {
  color: var(--app-blue);
  cursor: pointer;
  letter-spacing: 0.5px;
}

.table_item_text:hover {
  text-decoration: underline;
}

.empty_list_container {
  justify-content: center;
  align-items: center;
  padding: 200px 0px;
}

.empty_list_text {
  font-weight: bold;
  color: var(--text-secondary);
}

.auto_approved_text {
  position: absolute;
  right: 5px;
  top: 25%;
  color: var(--status-green);
}

.header_container {
  padding: 10px;
}
