.option_icon_container {
  width: 29px;
  align-self: center;
  display: flex;
  flex-direction: row-reverse;
}

.options_icon {
  color: var(--app-blue);
  scale: 1.2;
  align-self: center;
  cursor: pointer;
}

.tooltip_container {
  min-width: 0 !important;
  padding: 0 !important;
}

.option_menu {
  color: var(--app-blue);
  font-size: 14px;
}

.option_item {
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.option_item + .option_item {
  border-top: 1px solid var(--app-border-grey);
}
