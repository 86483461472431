.list_title {
  font-weight: 600;
}

.list_container {
  overflow-y: scroll;
  max-height: 200px;
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 8px;
  border-bottom: 1px solid var(--app-border-grey);
}

.list_element {
  padding-bottom: 8px;
  padding: 0 4px 8px;
  border-bottom: 1px solid var(--app-border-grey);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bottom_row_list_element {
  border-width: 0px;
}
