.image {
  width: 70%;
  border: none;
}

.header_style {
  border-bottom: solid;
  border-width: 1px;
  border-color: var(--app-border-grey);
  font-weight: bolder;
  line-height: 1.5;
  font-size: 18px;
  margin-bottom: 20px;
}

.group {
  display: flex;
  margin-bottom: 10px;
}

.label_group {
  text-align: center;
  width: 100%;
  height: 90px;
  margin: 5px;
  padding: 10px;
}

.value {
  font-size: 30px;
}

.backlog_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.backlog_title {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 21px;
  font-weight: 700;
}

.right_container {
  width: 100%;
  margin-right: 30px;
  margin-top: 30px;
}

.graph_container {
  width: 90%;
  max-width: 800px;
}

.graph_skeleton {
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  vertical-align: bottom;
  justify-content: space-between;
  margin: 0 15px;
  height: 250px;
  gap: 25px;
}

.graph_skeleton_container {
  display: flex;
  flex: 1;
  height: 100%;
  align-items: flex-end;
}

.graph_skeleton_bar {
  min-width: 50px;
}

.graph_skeleton_bar1 {
  min-width: 50px;
  height: 25%;
  flex: 1;
}

.graph_skeleton_bar2 {
  min-width: 50px;
  height: 50%;
  flex: 1;
}

.graph_skeleton_bar3 {
  min-width: 50px;
  height: 75%;
  flex: 1;
}

.graph_skeleton_bar4 {
  min-width: 50px;
  height: 100%;
  flex: 1;
}
