.button_container {
  display: flex;
  margin-right: 3.3%;
  padding: 12px 0px;
  justify-content: flex-end;
}

.button_container > button {
  border: none;
}

.button_container > button:disabled {
  opacity: 0.2;
}

.button_container > button:hover {
  opacity: 0.7;
}

.blue_button {
  height: 36px;
  background-color: var(--app-blue);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  border-radius: 3px;
  margin-left: 20px;
  padding: 0px 18px;
  /* text */
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: var(--app-white);
}

.blue_button[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

.gray_button {
  height: 36px;
  background-color: var(--app-offwhite);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 18px;
  border-radius: 3px;
  /* text */
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  color: var(--text-primary);
}
