.partial_loading_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.partial_loading_circle_container {
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partial_loading_message {
  font-weight: bold;
  font-size: 18px;
  color: var(--app-lightgrey);
}
