.section_container {
  padding: 10px 32px;
}

.header {
  margin-bottom: 20px;
  font-weight: bold;
}

.info_content_label {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;

  padding-bottom: 5px;
}

.add_content {
  display: flex;
}

.text_input_container {
  padding-bottom: 10px;
}

.dropdown_input_container {
  padding-bottom: 15px;
  width: 225px;
}

.scan_button {
  height: 36px;
  width: 100px;
  background-color: #258fe0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  margin: 2px 17px;
  border-radius: 3px;
  /* text */
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.410612px;
  color: var(--app-white);
}

.scan_button[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

.required_asterisk {
  font-weight: 700;
  font-size: var(--font-size-small);
  color: var(--status-red);
  margin-left: 3px;
}

.loading_message {
  font-size: var(--font-size-small);
}
