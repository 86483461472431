.flex_row {
  display: flex;
  flex-direction: row;
}
.flex_column {
  display: flex;
  flex-direction: column;
}
.container {
  flex: 1;
}
.side_column {
  width: 250px;
  border-right: 1px solid var(--app-border-grey);
}

.side_column > div:not(:last-child) {
  border-bottom: 1px solid var(--app-border-grey);
}

.content {
  gap: 30px;
  flex: 1;
  padding: 20px 40px;
}

.skeleton_main_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.skeleton_sidebar_container {
  display: flex;
  flex: 1;
  padding: 40px 20px;
}
