.file_drop_container {
  display: flex;
  gap: 10px;
  border: 2px dashed #ccc;
  text-align: center;
  transition: background-color 0.3s;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.file_drop_container_dragging {
  display: flex;
  gap: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  flex-direction: column;
  border: 2px solid var(--app-blue);
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.button {
  font-weight: 400;
  background-color: transparent;
  text-transform: none;
  letter-spacing: 0.02rem;
  padding: 5px 20px;
  border: none;
  border-radius: 0.15625rem;
  cursor: pointer;
  color: var(--app-white);
  text-align: center;
  font-size: 14px;
  background-color: var(--app-blue);
  white-space: nowrap;
  margin-bottom: 15px;
}

.button:hover {
  color: white;
  box-shadow: 0px 3px 9px 0px var(--neutral-primary);
}

.main_text_bold {
  font-size: 16px;
  font-weight: 600;
}

.helper_text_bold {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-secondary);
}