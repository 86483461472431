.button,
.button:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--app-blue);
  border-radius: 3px;
  color: var(--app-white);
  height: 35px;
  padding: 0px 20px;
  font-weight: bold;
}

.button:hover {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
}

.button:active {
  opacity: 0.9;
}
